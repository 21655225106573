<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave('PREPARE')"
      @head-submit="headSave('FINISHED')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <avue-form :option="formOptions" ref="addForm" v-model="addForm">
          <template slot="complainCode">
            <el-input
              placeholder="请输入记录编号"
              v-model="addForm.complainCode"
              :disabled="isAutomatic || pageDisabled || addForm.id || formId"
            >
              <span slot="append">
                自动生成
                <el-switch
                  :disabled="pageDisabled || addForm.id || formId"
                  v-model="isAutomatic"
                  active-color="#13ce66"
                  @change="handleSwitch"
                >
                </el-switch>
              </span>
            </el-input>
          </template>
          <template slot="userName">
            <div style="display: flex; align-items: center">
              <el-input
                placeholder="请输入投诉人"
                v-model="addForm.userName"
                :disabled="pageDisabled || checked"
              >
              </el-input>
              <el-checkbox
                style="margin-left: 10px"
                v-model="checked"
                :disabled="pageDisabled || addForm.id || formId"
                >匿名</el-checkbox
              >
            </div>
          </template>
          <template slot="files">
            <el-upload
              :disabled="pageDisabled"
              :action="'/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform'"
              list-type="picture-card"
              accept=".jpg,.png,.mp4"
              :limit="9"
              placeholder="请上传图片视频"
              :file-list="fileList"
              :headers="headers"
              :on-success="uploadSuccess"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :on-exceed="uploadExceed"
              :on-change="uploadChange"
              :before-upload="uploadBefore"
              :class="{ uploadhideAdd: pageDisabled }"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M
              </div>
            </el-upload>
          </template>
        </avue-form>
      </div>

      <el-dialog
        title="预览"
        :visible.sync="dialogVisible"
        append-to-body
        top="5vh"
        custom-class="customDialog"
        v-if="dialogVisible"
      >
        <img
          style="width: 100%; height: 100%; object-fit: contain"
          :src="dialogImageUrl"
          alt=""
          v-if="uploadType == 'img'"
        />
        <video
          style="width: 100%; height: 100%; object-fit: contain"
          autoplay
          :src="dialogVideoUrl"
          v-if="uploadType == 'video'"
          controls="controls"
        ></video>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";

import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";
import * as API from "@/api/environmentProtect/publicSentiment";
import { getPageCode } from "@/api/system/serialNumber";

import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      addForm: {
        anonymous: "1",
      },
      fileList: [],
      isAutomatic: true,
      dialogVisible: false,
      dialogImageUrl: "", // 预览图片路径
      dialogVideoUrl: "", // 预览视频路径
      uploadType: "img", // 默认是图片 img video 是视频的查看方式
      propsHttp: {
        url: "link",
        name: "name",
        res: "data",
      },
      checked: false,
    };
  },
  watch: {
    checked(val) {
      if (val) {
        this.addForm.userName = "匿名";
        this.addForm.anonymous = "1";
      } else {
        this.addForm.anonymous = "0";
        this.addForm.userName = "";
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    pageDisabled() {
      // 如果查看 或者 提交状态（未执行）则禁用 已执行禁用
      if (this.formType == "view") {
        return true;
      } else {
        return false;
      }
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType) && !this.pageDisabled) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          btnOptType: "refer",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "记录编号",
            prop: "complainCode",
            span: 8,
            formslot: true,
            placeholder: "请输入记录编号",
            rules: [
              {
                required: true,
                message: "请输入记录编号",
                trigger: "blur",
              },
              {
                max: 32,
                message: "记录编号不能超出32字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "类型",
            prop: "type",
            span: 8,
            type: "radio",
            dicData: [
              {
                label: "环保舆情",
                value: 1,
              },
              {
                label: "环保投诉",
                value: 2,
              },
            ],
            value: 2,
            disabled: true,
            placeholder: "请选择类型",
            rules: [
              {
                required: true,
                message: "请选择类型",
                trigger: "change",
              },
            ],
          },
          {
            label: "投诉渠道",
            prop: "channel",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=ep_complain_channel",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            disabled: this.pageDisabled,
            placeholder: "请选择投诉渠道",
            rules: [
              {
                required: true,
                message: "请选择投诉渠道",
                trigger: "change",
              },
            ],
          },
          {
            label: "投诉人",
            prop: "userName",
            span: 8,
            formslot: true,
            disabled: this.pageDisabled,
            placeholder: "请输入投诉人",
            rules: [
              {
                required: true,
                message: "请输入投诉人",
                trigger: "blur",
              },
              {
                max: 30,
                message: "内容描述不能超过30个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "联系方式",
            // labelTip: "我是一个标题提示语",
            prop: "tel",
            span: 8,
            disabled: this.pageDisabled,
            placeholder: "请输入联系方式",
            rules: [
              {
                required: false,
                message: "请输入联系方式",
                trigger: "blur",
              },
              { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式错误" },
            ],
          },
          {
            label: "投诉日期",
            prop: "complainDate",
            span: 8,
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            value: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
            placeholder: "请选择投诉日期",
            disabled: this.pageDisabled,
            rules: [
              {
                required: true,
                message: "请选择投诉日期",
                trigger: "blur",
              },
            ],
          },
          {
            label: "投诉内容",
            prop: "content",
            disabled: this.pageDisabled,
            span: 24,
            type: "textarea",
            placeholder: "请输入投诉内容",
            maxlength: 255,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入投诉内容",
                trigger: "blur",
              },
              {
                max: 255,
                message: "内容描述不能超过255个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "附件",
            prop: "files",
            span: 24,
            formslot: true,
            type: "upload",
            headers: this.headers,
            listType: "picture-card",
            accept: "image/png, image/jpg, video/mp4",
            limit: 9,
            propsHttp: {
              res: "data",
            },
            action:
              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
            tip: "图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M",
            placeholder: "请上传附件",
            rules: [
              {
                required: false,
                message: "请上附件",
                trigger: "change",
              },
            ],
          },
          {
            label: "处置方式及结果",
            prop: "remark",
            disabled: this.pageDisabled,
            span: 24,
            type: "textarea",
            maxlength: 255,
            showWordLimit: true,
            placeholder: "请输入后续处置方式及结果",
            rules: [
              {
                max: 255,
                message: "备注说明不能超过255个字符",
                trigger: "blur",
              },
              {
                required: false,
                message: "请输入处置方式及结果",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    async init() {
      let type = this.$route.query.type;
      this.formType = type;
      this.headTitle = "环保舆情基本信息";
      if (this.formType == "add") {
        await this.handleSwitch();
      } else if (this.formType == "edit") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      } else if (this.formType == "view") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      }
    },
    // 基本信息预案编号是否自动生成
    async handleSwitch() {
      if (this.isAutomatic) {
        await this.getPageCode();
      } else {
        this.addForm.complainCode = "";
      }
    },
    async getPageCode() {
      // 获取编码
      await getPageCode({ ruleCode: "complain_code" }).then((res) => {
        this.$set(this.addForm, "complainCode", res.data.data || "");
        this.$forceUpdate();
      });
    },

    headSave(status) {
      this.saveOrSubmitFor(status);
    },
    // 保存提交改变状态
    saveOrSubmitFor(type) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {

          this.saveLoading = true;
          this.addForm.files = this.fileList;
          this.addForm.status = type == "PREPARE" ? 0 : 1;
          if (
            type == "FINISHED" ||
            this.formType == "edit" ||
            (this.formType == "add" && this.formId)
          ) {
            this.addForm.id = this.formId;
          }
          this.addForm.orgId = this.userInfo.dept_id;
          this.addForm.orgCode = this.userInfo.dept_id;
          const params = JSON.parse(JSON.stringify(this.addForm));
          params.files = JSON.stringify(params.files);
          delete params.type;
          delete params.$channel;
          delete params.$type;
          API.epcomplainSubmit(params)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                // this.addForm = data;
                this.formId = data.id;
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                if (data.status) this.formType = "view";
              } else {
                this.$message.error(msg);
              }
              // this.$refs.addForm.resetFields();
              done();
              this.saveLoading = false;
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 获取详情
    getDetails(id) {
      API.epcomplainDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          const { data } = res.data;

          this.addForm.channel = data.channel;
          this.addForm.complainDate = data.complainDate;
          this.addForm.complainCode = data.complainCode;
          this.addForm.content = data.content;
          this.addForm.userName = data.userName;
          this.addForm.remark = data.remark;
          this.addForm.orgCode = data.orgCode;
          this.addForm.orgId = data.orgId;
          this.addForm.tel = data.tel;
          // this.addForm.files = JSON.parse(data.files);
          this.fileList = JSON.parse(data.files);

        }
      });
    },

    // 判断文件是否为video
    isJudgeVideo(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "mp4" || fileName == "MP4") {
        //视频附件，获取第一帧画面作为 封面展示
        return true;
      } else {
        return false;
      }
    },
    /**
     * 获取视频第一帧作为回显封面
     */
    getVideoCover(file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      file.url = require("@/assets/images/videoLoading.gif"); //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      let _self = this;
      _self.fileList = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          return item.response.data;
        } else {
          return item;
        }
      });
      let data = response.data;
      data.url = response.data.link;
      if (_self.isJudgeVideo(data)) {
        //视频附件，获取第一帧画面作为 封面展示
        _self.getVideoCover(data);
      }
    },
    // 上传失败回调
    handleError() {},
    // 文件预览
    handlePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "mp4" ||
        fileName == "MP4"
      ) {
        this.dialogVisible = true;
        // 如果是视频
        if (fileName == "mp4" || fileName == "MP4") {
          this.uploadType = "video";
          this.dialogVideoUrl = file.link;
        } else {
          this.uploadType = "img";
          this.dialogImageUrl = file.link;
        }
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.fileList = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      if (fileList.length == 0) {
        this.$refs.addForm.validateField("fileList");
      }
    },
    // 文件上传超出限制回调
    uploadExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 文件上传前的回调
    uploadBefore(file) {
      const isJPG = file.type === "image/jpg" || "image/png" || "video/mp4";
      let maxNum = file.type == "video/mp4" ? 20 : 5;
      const isLt2M = file.size / 1024 / 1024 < maxNum;

      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式;视频仅支持*MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M;视频大小不超过20M!");
      }
      return isJPG && isLt2M;
    },
    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.addForm.validateField("fileList");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}
</style>
